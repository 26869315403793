export const environment = {
  apiUrl: 'https://apioperativerisk.qa.zeebra.tech',
  // apiUrl: 'https://apioperativerisk.partners.com.ec',
  // apiUrl: 'http://localhost:3041',
  production: true,
  transportSecret: 'Tr4s1$$',
  adminRole: 'admin',
  agentRole: 'agent',
  campaignConfig: {
    types: [
      { label: 'Cobranzas', value: 'COLLECTION' },
    ],
    callStatus: [
      { label: 'CONTACTADO', value: 'CONTACTED' },
      { label: 'NO CONTACTADO', value: 'NOT_CONTACTED' },
    ],
    managementStatus: [
      { label: 'PAGADO', value: 'PAID' },
      { label: 'PROBLEMA', value: 'PROBLEM' },
      { label: 'NO LOCALIZADO', value: 'NOT_LOCALIZED' },
      { label: 'MENSAJE', value: 'MESSAGE' },
      { label: 'COMPROMISO', value: 'COMMITMENT' },
    ],
    paid_ranges: [
      { label: '1 a 4', value: '1_4' },
      { label: '5 a 12', value: '5_12' },
      { label: 'más de 12', value: '13_100' }
    ]
  }
};
